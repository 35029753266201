import qa from "../Assets/Images/cv/qa.png";
import learningTree from "../Assets/Images/cv/learning_tree.png";
import dmu from "../Assets/Images/cv/dmu.png";
import cecil from "../Assets/Images/cv/cecil_jones.png";

const education = [
  {
    img: qa,
    imgAlt: "QA",
    title: "ITIL Foundation qualification",
    period: "Nov 2017 - Nov 2017",
  },
  {
    img: learningTree,
    imgAlt: "Learning Tree",
    title: "AGILE PROJECT MANAGEMENT WITH SCRUM",
    period: "June 2013 - June 2013",
  },
  {
    img: dmu,
    imgAlt: "De Montfort University",
    title: "MEDIA TECHNOLOGY BSc",
    period: "September 1998 - June 2001",
  },
  {
    img: cecil,
    imgAlt: "Cecil Jones High School",
    title: "GCSE'S & A LEVELS",
    period: "September 1991 - June 1998",
  },
];

export default education;
