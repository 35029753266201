import {
  faRocket,
  faUser,
  faQuestion,
  faGraduationCap,
} from "@fortawesome/free-solid-svg-icons";

const aboutTabs = {
  aboutMe: {
    tooltip: "About Me",
    icon: faUser,
  },
  skill: {
    tooltip: "My Skills",
    icon: faRocket,
  },
  why: {
    tooltip: "Why Me",
    icon: faQuestion,
  },
  vision: {
    tooltip: "My Vision",
    icon: faGraduationCap,
  },
};

export default aboutTabs;
