import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./index.module.css";

const ButtonRound = ({ selected, icon, ...nativeProps }) => {
  const selectClass = selected ? styles.btnSelected : null;

  return (
    <button
      type="button"
      className={`${styles.btn} ${selectClass}`}
      {...nativeProps}
    >
      <FontAwesomeIcon data-testid="button-round-icon" icon={icon} />
    </button>
  );
};

export default ButtonRound;
