import React from "react";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";
import Author from "../../Component/Author";
import Button from "../../Component/Button";
import styles from "./blogPost.module.css";

const BlogPost = ({ post }) => {
  const { id, title, date, excerpt, author } = post;
  const cleanExcerpt = DOMPurify.sanitize(excerpt, {
    USE_PROFILES: { html: true },
  });
  const cleanTitle = DOMPurify.sanitize(title, {
    USE_PROFILES: { html: true },
  });

  return (
    <>
      <div className={styles.postContainer}>
        <div className={styles.postTitleContainer}>
          <h3 dangerouslySetInnerHTML={{ __html: cleanTitle }} />
          <Author author={author} />
        </div>
        <div className={styles.postDate}>
          {format(new Date(date), "do MMM yyyy")}
        </div>
        <div
          className={styles.postSummary}
          dangerouslySetInnerHTML={{ __html: cleanExcerpt }}
        />
        <div className={styles.postActions}>
          <Link to={`/blog/${id}`}>
            <Button
              data-testid={`btn-id-${id}`}
              type="button"
              label="Read more"
              color="green"
              icon={faBook}
            />
          </Link>
        </div>
      </div>
    </>
  );
};

export default BlogPost;
