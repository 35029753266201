import React, { useEffect } from "react";
import Header from "../../Component/Header";
import Footer from "../../Component/Footer";
import HomeTestimonials from "../Home/HomeTestimonials";
import HomeCompanies from "../Home/HomeCompanies";

const TestimonialsPage = ({ title }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <>
      <Header excludeSubMenu />
      <div className="testimonials-container">
        <HomeTestimonials />
      </div>
      <HomeCompanies />
      <Footer />
    </>
  );
};

export default TestimonialsPage;
