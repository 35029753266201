import React from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Container from "../../Component/Container";
import Section from "../../Component/Section";
import Timeline from "../../Component/Timeline";
import SectionTitle from "../../Component/SectionTitle";
import career from "../../Data/career";

const HomeEducation = () => {
  return (
    <Section id="timeline" grey>
      <Container>
        <SectionTitle
          title="Work &amp; Education"
          icon={faBriefcase}
          subTitle={
            <>
              Below is a brief view of work and education. For more details
              please review my <Link to="/work/cv">CV</Link>.
            </>
          }
        />
        <Timeline items={career} />
      </Container>
    </Section>
  );
};

export default HomeEducation;
