import axios from "axios";
import { useQuery } from "react-query";
import { basePath } from "./global";

const usePosts = (page, searchTerm) => {
  // See postman if we are missing anything
  const includes = [
    "id",
    "title",
    "author",
    "date_gmt",
    "excerpt",
    "link",
    "categories",
    "_links",
    "_embedded",
  ];

  let pageStr, searchStr;
  const queryKey = ["posts"];

  if (searchTerm) {
    searchStr = `search=${searchTerm}&`;
    pageStr = "";
    queryKey.push("search", searchTerm);
  } else {
    searchStr = "";
    pageStr = page === 1 ? "" : `page=${page}&`;
    queryKey.push(page);
  }

  return useQuery(
    queryKey,
    () => {
      return axios
        .get(
          `${basePath}/index.php/wp-json/wp/v2/posts?${pageStr}${searchStr}_fields=${includes.join(
            ","
          )}&_embed`
        )
        .then((res) => {
          const posts = res.data.map((post) => ({
            id: post.id,
            title: post.title.rendered,
            author: post._embedded.author[0],
            date: post.date_gmt,
            excerpt: post.excerpt.rendered,
            link: post.link,
          }));

          return {
            posts: posts,
            total: parseInt(res.headers["x-wp-total"], 10),
            totalPages: parseInt(res.headers["x-wp-totalpages"], 10),
          };
        });
    },
    { staleTime: 1000 * 60 * 30 }
  );
};

export default usePosts;
