import React from "react";
import Carousel from "../../Component/Carousel";
import Container from "../../Component/Container";
import Section from "../../Component/Section";
import companies from "../../Data/companies";
import styles from "./HomeCompanies.module.css";

const HomeCompanies = () => {
  const companiesRef = React.createRef();

  return (
    <Section grey>
      <Container>
        <Carousel ref={companiesRef}>
          {companies.map((group, index) => (
            <div
              key={`company-group-${index}`}
              className={styles.clientContainer}
            >
              {group.images.map((company, index) => (
                <div key={index} className={styles.client}>
                  <img
                    src={company.src}
                    alt={company.alt}
                    className={styles.clientImg}
                  />
                </div>
              ))}
            </div>
          ))}
        </Carousel>
      </Container>
    </Section>
  );
};

export default HomeCompanies;
