import React from "react";
import styles from "./index.module.css";

const Card = ({ card }) => (
  <div key={card.id} className={styles.cardItem}>
    <div className={styles.cardImage}>
      <img className={styles.cardImg} src={card.img} alt={card.imgAlt} />
    </div>
    <div className={styles.cardItemDetails}>
      <h4 className={styles.cardTitle}>
        <span className={styles.cardCategory}>{card.category}</span>
        {card.externalLink ? (
          <a
            data-testid={`card-anchor-${card.id}`}
            href={card.externalLink}
            target="_blank"
            rel="noreferrer"
          >
            {card.title}
          </a>
        ) : (
          card.title
        )}
      </h4>
      <p className={styles.cardInfo}>{card.info}</p>
    </div>
  </div>
);

export default Card;
