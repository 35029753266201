import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import ScrollToTop from "./Utils/scrollToTop";
import ErrorBoundary from "./Component/ErrorBoundary";
import AboutPages from "./Pages/About";
import BlogPage from "./Pages/Blog";
import PostPage from "./Pages/BlogPost";
import ContactPage from "./Pages/Contact";
import HomePage from "./Pages/Home";
import NotFoundPage from "./Pages/NotFound";
import TestimonialsPage from "./Pages/Testimonials";
import WorkPages from "./Pages/Work";

const pageTitle = "Welcome to Nick Bennett's site";
const queryClient = new QueryClient();

function App() {
  return (
    <Router>
      <ScrollToTop />
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <Switch>
            <Route path="/about">
              <AboutPages title={pageTitle} />
            </Route>
            <Route exact path="/blog">
              <BlogPage />
            </Route>
            <Route exact path="/blog/:postId">
              <PostPage />
            </Route>
            <Route exact path="/contact">
              <ContactPage />
            </Route>
            <Route exact path="/testimonials">
              <TestimonialsPage title={`${pageTitle} | Testimonials`} />
            </Route>
            <Route path="/work">
              <WorkPages title={pageTitle} />
            </Route>
            <Route exact path="/">
              <HomePage title={pageTitle} />
            </Route>
            <Route>
              <NotFoundPage title={`${pageTitle} | 404`} />
            </Route>
          </Switch>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ErrorBoundary>
    </Router>
  );
}

export default App;
