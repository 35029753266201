import ajb from "../Assets/Images/work/companies/ajbell.png";
import cutover from "../Assets/Images/work/companies/cutover.png";
import dl from "../Assets/Images/work/companies/digital_look.png";
import gh from "../Assets/Images/work/companies/go-henry.png";
import ii from "../Assets/Images/work/companies/interactive_investor.png";
import nomad from "../Assets/Images/work/companies/nomad_black.png";
import rj from "../Assets/Images/work/companies/rjis.png";

const companies = [
  {
    images: [
      {
        src: cutover,
        alt: "Cutover",
      },
      {
        src: gh,
        alt: "Go Henry",
      },
    ],
  },
  {
    images: [
      {
        src: ajb,
        alt: "AJ Bell",
      },
      {
        src: ii,
        alt: "Interactive Investor",
      },
    ],
  },
  {
    images: [
      {
        src: rj,
        alt: "Raymond James Investment Services",
      },
      {
        src: dl,
        alt: "Digital Look",
      },
    ],
  },
  {
    images: [
      {
        src: nomad,
        alt: "Nomad",
      },
    ],
  },
];

export default companies;
