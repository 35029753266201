import React, { useEffect } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { ganglebeez, player, rev11, lenAndSwifty } from "../../Data/music";
import NotFoundPage from "../NotFound";
import ContainerInfo from "../../Component/ContainerInfo";
import Header from "../../Component/Header";
import Hero from "../../Component/Hero";
import Footer from "../../Component/Footer";
import Title from "../../Component/Title";
import TypeAhead from "../../Component/TypeAhead";
import MusicAlbums from "./MusicAlbums";
import MusicGigs from "./MusicGigs";

const MusicPage = ({ band, members, albums, gigs, title }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <>
      <Header excludeSubMenu />
      <Hero type="music">
        <ContainerInfo>
          <Title>{band}</Title>
          <TypeAhead items={members} />
        </ContainerInfo>
      </Hero>
      {albums.length > 0 && <MusicAlbums albums={albums} />}
      {gigs.length > 0 && <MusicGigs gigs={gigs} />}
      <Footer />
    </>
  );
};

const MusicPages = ({ title }) => {
  let match = useRouteMatch();

  return (
    <>
      <Switch>
        <Route exact path={`${match.path}/ganglebeez`}>
          <MusicPage
            key="ganglebeez"
            title={`${title} | Ganglebeez`}
            {...ganglebeez}
          />
        </Route>
        <Route exact path={`${match.path}/player`}>
          <MusicPage key="player" title={`${title} | Player`} {...player} />
        </Route>
        <Route exact path={`${match.path}/len_and_swifty`}>
          <MusicPage
            key="len_and_swifty"
            title={`${title} | Len and Swifty`}
            {...lenAndSwifty}
          />
        </Route>
        <Route exact path={`${match.path}/rev11`}>
          <MusicPage key="rev11" title={`${title} | Rev11`} {...rev11} />
        </Route>
        <Route>
          <NotFoundPage title={`${title} | 404`} />
        </Route>
      </Switch>
    </>
  );
};

export default MusicPages;
