import React from "react";
import bob from "../../Assets/Images/blog/authors/bobsy.png";
import nick from "../../Assets/Images/blog/authors/nick.png";
import styles from "./index.module.css";

const getAuthorImgUrl = (author) => {
  let imgUrl = "";

  switch (author.slug) {
    case "nickbennett":
      imgUrl = nick;
      break;

    case "bobtall":
      imgUrl = bob;
      break;

    default:
      imgUrl = author.avatar_urls["96"];
      break;
  }

  return imgUrl;
};

const Author = ({ author }) => {
  return (
    <div className={styles.container}>
      <img
        src={getAuthorImgUrl(author)}
        alt={author.name}
        className={styles.img}
      />
    </div>
  );
};

export default Author;
