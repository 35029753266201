import React from "react";
import Container from "../Container";
import styles from "./index.module.css";

const Hero = ({ children, type = "standard", short = false }) => {
  let typeClass = "";

  switch (type) {
    case "music":
      typeClass = styles.heroMusic;
      break;

    case "cv":
      typeClass = styles.heroCv;
      break;

    default:
      break;
  }

  const shortClass = short ? styles.heroShort : null;
  const shortIntroClass = short ? styles.heroIntroShort : null;
  return (
    <section
      data-testid="hero-container"
      className={`${styles.hero} ${typeClass} ${shortClass}`}
    >
      <div className={styles.heroCover}>
        <Container>
          <div
            data-testid="hero-intro"
            className={`${styles.heroIntro} ${shortIntroClass}`}
          >
            {children}
          </div>
        </Container>
      </div>
    </section>
  );
};

export default Hero;
