import prophecy from "../Assets/Images/about/music/player/prophecy.png";
import demos from "../Assets/Images/about/music/player/demos.png";
import theShining from "../Assets/Images/about/music/player/the_shining.png";
import modernArt from "../Assets/Images/about/music/lenandswifty/modern_art.png";

export const ganglebeez = {
  band: "Ganglebeez",
  members: ["Mark Bateman", "Nick Bennett", "Gary Sackman"],
  albums: [],
  gigs: [
    {
      month: "June",
      year: "1997",
      title: "Gary's House, Essex",
      details:
        "Gary’s house warming. The set list included only 3 songs and we were paid with an unlimited supply of alcohol. Being 16 this was a chance to prove my manhood. In true rock and roll fashion, I got really drunk, brushed my teeth, went to bed and woke up in the own vomit. The music was exactly how you imagine it to be.",
    },
  ],
};

export const player = {
  band: "Player",
  members: [
    "Bob Tall",
    "Nick Bennett",
    "Mike Halliday",
    "Craig Cutmore",
    "Vaughan Abrey",
  ],
  albums: [
    {
      img: prophecy,
      name: "The Prophecy",
      link: "https://w.soundcloud.com/player/?url=httpss%3A//api.soundcloud.com/playlists/60684972&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false&amp;visual=true",
      releaseMonth: "Feb",
      releaseYear: "1998",
    },
    {
      img: demos,
      name: "Demos",
      link: "https://w.soundcloud.com/player/?url=httpss%3A//api.soundcloud.com/playlists/59846299&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false&amp;visual=true",
      releaseMonth: "Oct",
      releaseYear: "2000",
    },
    {
      img: theShining,
      name: "The Shining",
      link: "https://w.soundcloud.com/player/?url=httpss%3A//api.soundcloud.com/playlists/59847317&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false&amp;visual=true",
      releaseMonth: "Mar",
      releaseYear: "2009",
    },
  ],
  gigs: [
    {
      month: "July",
      year: "2004",
      title: "Army & Navy, Chelmford",
      details:
        "The final gig that I played with the band. An interesting evening as we supported the Inspiral Carpert/The Farm hybrid band. The sound was amazing and we played a tight set. We had to do a runner after as I had promised to sell the main acts merchandise for them, but just wanted to get out of there after our set.",
    },
    {
      month: "June",
      year: "2003",
      title: "The golden arms, Bicester",
      details:
        'The furthest gig we played. It was an impromptu affair. A local moaned at us and insisted on ""No more bloody Oasis"" songs at the interval. Bob decided that the second half of the set would be compiled entirely of Oasis songs. A sign perhaps that we would never be entirely taken to the hearts of the British public.',
    },
    {
      month: "August",
      year: "2002",
      title: "Chinnerys, Southend",
      details:
        "Our home ground of venues as it was also where we rehearsed. We played a few gigs here. This included several \"Battle of the bands\" and playing for a record exec. Highlights for me were the time I'd decided to stay off the sauce and was necking Red Bulls to handle the nerves. Unfortunately, it meant I'd finished the set 10 minutes sooner than everyone else.",
    },
    {
      month: "July",
      year: "2002",
      title: "The Grand, Leigh",
      details:
        "Sadly no longer there the grand hosted a couple of Player gigs. The largest was to over 100 dedicated Player fans...plus friends and family. The gig consisted mainly of our own material with a couple of Brit-pop anthems thrown in for good measure. These gigs were where I picked up the nickname the statue for my incredible stage presence.",
    },
  ],
};

export const rev11 = {
  band: "Rev11",
  members: ["Bob Tall", "Nick Bennett", "Tom Draper", "Tony Earp"],
  albums: [],
  gigs: [],
};

export const lenAndSwifty = {
  band: "Len and Swifty",
  members: ["Nick Bennett", "Steve Swift"],
  albums: [
    {
      img: modernArt,
      name: "Modern Art",
      link: "https://w.soundcloud.com/player/?url=httpss%3A//api.soundcloud.com/playlists/60725444&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false&amp;visual=true",
      releaseMonth: "Sep",
      releaseYear: "2006",
    },
  ],
  gigs: [],
};
