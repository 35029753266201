import React from "react";
import { CSSTransition } from "react-transition-group";
import styles from "./index.module.css";
import "./index.css";

const TypeAhead = ({ staticTitle, items }) => {
  const currentItemIndex = React.useRef(0);
  const [show, setShow] = React.useState(false);
  const wrapper = React.createRef();

  React.useEffect(() => {
    setShow(true);
  }, []);

  return (
    <div>
      <h2 className={styles.typeAheadTitle}>
        {staticTitle}{" "}
        <CSSTransition
          in={show}
          timeout={1400}
          classNames="typed"
          unmountOnExit
          onEntered={() => {
            setShow(false);
          }}
          onExited={() => {
            currentItemIndex.current =
              currentItemIndex.current === items.length - 1
                ? 0
                : currentItemIndex.current + 1;
            setShow(true);
          }}
          nodeRef={wrapper}
        >
          <span className={styles.typeAheadChanger} ref={wrapper}>
            <b className={styles.typeAheadChangerItem}>
              {items[currentItemIndex.current]}
            </b>
          </span>
        </CSSTransition>
      </h2>
    </div>
  );
};

export default TypeAhead;
