import React from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import Hero from "../../Component/Hero";
import TypeAhead from "../../Component/TypeAhead";
import Button from "../../Component/Button";
import ContainerInfo from "../../Component/ContainerInfo";
import Title from "../../Component/Title";
import { roleTitles } from "../../Data/roles";

const HomeHero = () => {
  return (
    <Hero>
      <ContainerInfo>
        <Title>My name is Nick Bennett</Title>
        <TypeAhead staticTitle={"A Professional"} items={roleTitles} />
        <div>
          {/* <Link className="btn btn--green" to={{ hash: "#contact" }}>
            <FontAwesomeIcon
              data-testid="menu-toggle-icon"
              icon={faRocket}
              className="btn__icon"
            />
            Hire Me
          </Link> */}
          {/* React router link didn't work for this */}
          <Button
            icon={faBriefcase}
            color="white"
            label="My Projects"
            onClick={() => {
              document.getElementById("portfolio").scrollIntoView();
            }}
          />
        </div>
      </ContainerInfo>
    </Hero>
  );
};

export default HomeHero;
