import {
  faPlug,
  faClipboardList,
  faLaptop,
  faTruckMoving,
} from "@fortawesome/free-solid-svg-icons";

const services = [
  {
    heading: "Integration",
    icon: faPlug,
    info: "Implement new entry points or connect to any 3rd party services.",
  },
  {
    heading: "Automation",
    icon: faClipboardList,
    info: "Schedule jobs or introduce Pub/Sub queues for asynchronous computing.",
  },
  {
    heading: "Client Side",
    icon: faLaptop,
    info: "Using React/Angular to display and interact with key API data",
  },
  {
    heading: "Delivery",
    icon: faTruckMoving,
    info: "Working with CI/CD to ensure smooth and risk-free deployments",
  },
];

export default services;
