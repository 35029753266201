import axios from "axios";
import { useQuery } from "react-query";
import { basePath } from "./global";

const getFeaturedImageFromMedia = (media) => {
  if (
    !media.hasOwnProperty("type") ||
    media["type"] !== "attachment" ||
    !media.hasOwnProperty("media_type") ||
    media["media_type"] !== "image"
  ) {
    return null;
  }

  return {
    sourceUrl: media["source_url"],
    availableSizes: media["media_details"]["sizes"],
  };
};

const getPostCategories = (post) => {
  if (!post.hasOwnProperty("_embedded")) {
    return [];
  }

  if (!post["_embedded"].hasOwnProperty("wp:term")) {
    return [];
  }

  const categories = post["_embedded"]["wp:term"][0];

  if (!Array.isArray(categories)) {
    return [];
  }

  return categories.map((category) => {
    return {
      id: category.id,
      name: category.name,
      slug: category.slug,
    };
  });
};

// Annoyingly WP API doesn't flag manually added excerpts from auto generated ones
const getExcerptFromPost = (post) => {
  if (!post.hasOwnProperty("excerpt")) {
    return null;
  }

  // Remove HTML tags from excerpt
  const excerpt = post.excerpt.rendered.replace(/(<([^>]+)>)/gi, "");

  // Remove HTML tags from content
  const content = post.content.rendered.replace(/(<([^>]+)>)/gi, "");

  // Compare 1st 30 chars of excerpt and content
  if (excerpt.substring(0, 30).trim() === content.substring(0, 30).trim()) {
    return null;
  }

  return post.excerpt.rendered;
};

const usePost = (postId) => {
  return useQuery(
    ["post", postId],
    () => {
      return axios
        .get(`${basePath}/index.php/wp-json/wp/v2/posts/${postId}?_embed`)
        .then((res) => {
          const post = res.data;

          return {
            id: post.id,
            featuredImage: post["_embedded"].hasOwnProperty([
              "wp:featuredmedia",
            ])
              ? getFeaturedImageFromMedia(
                  post["_embedded"]["wp:featuredmedia"][0]
                )
              : null,
            title: post.title.rendered,
            author: post._embedded.author[0],
            excerpt: getExcerptFromPost(post),
            categories: getPostCategories(post),
            date: post.date_gmt,
            content: post.content.rendered,
            link: post.link,
          };
        });
    },
    { staleTime: 1000 * 60 * 30 }
  );
};

export default usePost;
