import {
  faHome,
  faRocket,
  faBriefcase,
  faHistory,
  faStar,
  faPen,
  // faAddressBook,
} from "@fortawesome/free-solid-svg-icons";

export const menuConfig = [
  {
    id: "home-hero",
    icon: faHome,
    label: "Top",
  },
  {
    id: "my-service",
    icon: faRocket,
    label: "My Service",
  },
  {
    id: "portfolio",
    icon: faBriefcase,
    label: "Portfolio",
  },
  {
    id: "timeline",
    icon: faHistory,
    label: "Timeline",
  },
  {
    id: "testimonials",
    icon: faStar,
    label: "Testimonials",
  },
  {
    link: "/blog",
    icon: faPen,
    label: "Blog",
  },
  // TODO: Re-instate when these are ready
  // {
  //   id: "contact",
  //   icon: faAddressBook,
  //   label: "Contact",
  // },
];

export const blogConfig = [
  {
    link: "/",
    icon: faHome,
    label: "Home",
  },
  {
    link: "/blog",
    icon: faPen,
    label: "Blog",
  },
];
