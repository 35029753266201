import { faLaptop, faTasks, faUsers } from "@fortawesome/free-solid-svg-icons";
import se from "../Assets/Images/cv/software_engineer.png";
import sm from "../Assets/Images/cv/scrum_master.png";
import tl from "../Assets/Images/cv/team_lead.png";

const getYearsExperience = () => {
  const now = new Date();
  const dateStartedEngineering = new Date("2004-06-01T00:00:00");
  const dateDiff = now - dateStartedEngineering;

  return Math.floor(dateDiff / (1000 * 60 * 60 * 24 * 365));
};

const roles = [
  {
    id: "se",
    icon: faLaptop,
    image: se,
    label: "Software engineer",
    statement: `I am a software engineer with over ${getYearsExperience()} years of professional experience. I have worked up from a junior developer role to a more senior team lead role and part-time scrum master. I am always keen to learn new technologies and techniques.I have a range of skills under my belt, which means I'm as comfortable in the back-end code as I am in the front. I thrive on complex technical challenges and enjoy working with like-minded engineers.`,
  },
  {
    id: "sm",
    icon: faTasks,
    image: sm,
    label: "Scrum master",
    statement:
      "I am a scrum master with over 2 years professional experience in the Finance sector. Prior to this I was a software engineer with experience in the delivery of complex projects on a number of different platforms. I have experience at every level of the project life cycle, from requirements gathering to delivery. In my current role I have streamlined the development cycle and promoted agile across the company. I have good experience of all of the Atlassian products and am highly organised. I always push the team to be more productive and efficient whilst maintaining a high level of motivation. I am a good communicator and take great pride in my work.",
  },
  {
    id: "tl",
    icon: faUsers,
    image: tl,
    label: "Team lead",
    statement:
      "I am a senior software engineer with experience of leading small development teams. My experience of developing various code bases means I am able to mentor junior colleagues. I enjoy improving processes and altering them when they aren't working . I have led scrum meetings and understand how to motivate development teams. I am comfortable with appraisals and disciplinary procedures.",
  },
];

export default roles;

export const roleTitles = roles.map((role) => role.label);
