import React, { useEffect } from "react";
import Header from "../../Component/Header";
import Footer from "../../Component/Footer";
import Hero from "../../Component/Hero";
import Title from "../../Component/Title";

const NotFoundPage = ({ title }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <>
      <Header excludeSubMenu />
      <Hero type="cv">
        <Title withMargin>Whoops!</Title>
        <Title withMargin>This page does not exist</Title>
      </Hero>
      <Footer excludeTopLink />
    </>
  );
};

export default NotFoundPage;
