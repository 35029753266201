import React from "react";
import styles from "./index.module.css";

const Section = ({
  children,
  grey = false,
  classExtra = "",
  ...nativeProps
}) => (
  <section
    data-testid="section"
    className={`${styles.section} ${
      grey ? styles.sectionGrey : ""
    } ${classExtra}`}
    {...nativeProps}
  >
    {children}
  </section>
);

export default Section;
