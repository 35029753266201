import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import styles from "./index.module.css";

const Loader = ({ white }) => {
  const colorClass = white ? styles.white : null;

  return (
    <div className={styles.container}>
      <FontAwesomeIcon
        data-testid="loader-icon"
        icon={faSpinner}
        spin
        className={`${styles.img} ${colorClass}`}
      />
    </div>
  );
};

export default Loader;
