import React from "react";
import Block from "../Block";
import styles from "./index.module.css";

const Blocks = ({
  contentItems = [],
  size = "standard",
  noHover = false,
  includeContentHandler,
}) => {
  return (
    <div className={styles.blocks}>
      {contentItems.map((content, i) => {
        if (includeContentHandler && !includeContentHandler(content)) {
          return "";
        }

        return (
          <Block
            key={i}
            size={size}
            noHover={noHover}
            icon={content.icon}
            heading={content.heading}
            info={content?.info}
            content={content?.content}
          />
        );
      })}
    </div>
  );
};

export default Blocks;
