import {
  faRocket,
  faBriefcase,
  faTasks,
  faServer,
  faDotCircle,
} from "@fortawesome/free-solid-svg-icons";
import ajbell_Lisa from "../Assets/Images/work/projects/ajbell/lisa/lisa_1200_800.png";
import cutover from "../Assets/Images/work/projects/cutover/cutover_1200_800.png";
import dl_ilook from "../Assets/Images/work/projects/digitallook/i_look/i_look_1200_800.png";
import dl_pf from "../Assets/Images/work/projects/digitallook/personal_finance/personal_finance_1200_800.png";
import ii_beta from "../Assets/Images/work/projects/interactiveinvestor/beta/beta_1200_800.png";
import ii_telegraph from "../Assets/Images/work/projects/interactiveinvestor/telegraph/telegraph_1200_800.png";
import nb_ds from "../Assets/Images/work/projects/nickbennett/design_share/design_share_1200_800.png";
import nb_howmuch from "../Assets/Images/work/projects/nickbennett/how_much/how_much_1200_800.png";
import nb_mfe from "../Assets/Images/work/projects/nickbennett/my_full_english/my_full_english_1200_800.png";
import nb_story from "../Assets/Images/work/projects/nickbennett/story_tag/story_tag_1200_800.png";
import rjis from "../Assets/Images/work/projects/raymondjames/rjis/rjis_1200_800.png";
import sm from "../Assets/Images/work/projects/scrum_master_1200_800.png";

export const portfolioFilters = [
  {
    id: "all",
    label: "All work",
    keys: ["fs", "cs", "sm", "ss"],
    icon: faTasks,
  },
  {
    id: "full",
    label: "Full stack",
    keys: ["fs"],
    icon: faDotCircle,
  },
  {
    id: "client",
    label: "Client side",
    keys: ["cs"],
    icon: faRocket,
  },
  {
    id: "scrum",
    label: "Scrum master",
    keys: ["sm"],
    icon: faBriefcase,
  },
  // TODO: Check these icons
  {
    id: "server",
    label: "Server side",
    keys: ["ss"],
    icon: faServer,
  },
];

const portfolio = [
  {
    id: "cutover",
    img: cutover,
    imgAlt: "Cutover",
    title: "Cutover",
    info: "A combination of AngularJS and React/TypeScript components working alongside using SingleSPA. Supported via a Rails API with multiple 3rd party integrations connected via a Java gateway.",
    externalLink: "https://www.cutover.com/",
    filter: "fs",
    featured: true,
  },
  {
    id: "youinvest",
    img: ajbell_Lisa,
    imgAlt: "Lifetime ISA",
    title: "Application process",
    info: "An SPA written in JQuery using Handlebar.js templates. A service-driven form flow for user validation. Use of 3rd party services to verify new users before forwarding to internal mid-tier onboarding service.",
    externalLink: "https://www.youinvest.co.uk/apply/lifetime-isa",
    filter: "fs",
    featured: true,
  },
  {
    id: "howmuch",
    img: nb_howmuch,
    imgAlt: "How much deposit do I need?",
    title: "How much deposit do I need",
    info: "An SPA Angular application, which used the user's Geolocation combined with Zoopla's average house prices to determine the minimum deposit requirements.",
    externalLink: "http://howmuchdepositdoineed.co.uk",
    filter: "cs",
    featured: false,
  },
  {
    id: "interactive",
    img: ii_beta,
    imgAlt: "Beta project",
    title: "Interactive Investor - Beta",
    info: "A PHP ZF2 application, which used Drupal as a service provider for content. Front-end components were written in Angular and used web sockets to connect to a NodeJS middleware.",
    externalLink: "",
    filter: "cs",
    featured: true,
  },
  {
    id: "interactive-sm",
    img: sm,
    imgAlt: "Scrum master",
    title: "Interactive Investor - Beta",
    info: "Delivered the MVP of the re-write within the required timeframe. Hosted the scrum ceremonies and managed the product backlog via Jira. Ensured documentation was kept organised and up to date within Confluence. ",
    externalLink: "",
    filter: "sm",
    featured: false,
  },
  {
    id: "telegraph",
    img: ii_telegraph,
    imgAlt: "Telegraph",
    title: "Telegraph trading platform",
    info: "Using PHP ZF1 and configuration (via MySQL), I contributed towards converting the core trading platform into a white label service. Configuration paths allowed custom directories for images and CSS to ensure a unqiue feel for every white label instance.",
    externalLink: "",
    filter: "ss",
    featured: false,
  },
  {
    id: "payroll",
    img: sm,
    imgAlt: "Scrum master",
    title: "RJIS - Payroll",
    info: "Completed the Agile project management course and led the payroll project through to delivery. I managed all of the scrum meetings and promoted Agile principles throughout the org. Maintained a physical scrum board.",
    externalLink: "",
    filter: "sm",
    featured: false,
  },
  {
    id: "myfullenglish",
    img: nb_mfe,
    imgAlt: "My full English",
    title: "My Full English",
    info: "A web application using ZF2, JQuery and other plugins to allow users to find and review full English breakfasts. Google maps API was used to log venues and show directions. Bootstrap 3 ensured the site was responsive.",
    externalLink: "",
    filter: "fs",
    featured: false,
  },
  {
    id: "storytag",
    img: nb_story,
    imgAlt: "Story tag",
    title: "Story Tag",
    info: 'A web application built using PHP5 and MySQL. It allowed collaboration on screenplays. The front page had a rudimental AI component to act as an FAQ. The UX allowed users to "play" their screenplay as if it were a SMS conversation.',
    externalLink: "",
    filter: "fs",
    featured: true,
  },
  {
    id: "rjis",
    img: rjis,
    imgAlt: "Raymond James Investment Services",
    title: "RJIS",
    info: "A web application built using a custom PHP framework based on CodeIgnitor. The authenticated portal showed the clients their holdings. User access controls meant the same application could be used internally as an intranet. The portal included its own custom CMS system.",
    externalLink: "https://www.raymondjames.uk.com/",
    filter: "fs",
    featured: true,
  },
  {
    id: "ilook",
    img: dl_ilook,
    imgAlt: "DigitalLook iLook",
    title: "iLook",
    info: "A single page widget view built using JQuery and plugins. It allowed small interactive data components to be added, moved and customised on a single dashboard. We persisted state so it would remember the user's layout.",
    externalLink: "http://www.digitallook.com/i-look",
    filter: "fs",
    featured: true,
  },
  {
    id: "personalfinance",
    img: dl_pf,
    imgAlt: "Personal finance",
    title: "DigitalLook - Personal Finance",
    info: "Gave the users a view of financial products and services. Cron scheduled script processed daily XML feeds. These were then displayed on the page. The codebase was a LAMP stack using Perl.",
    externalLink: "",
    filter: "ss",
    featured: false,
  },
  {
    id: "designshare",
    img: nb_ds,
    imgAlt: "Design share",
    title: "Design Share",
    info: "This web app was built on a LAMP stack. It was for crowd sourcing any design work request and provide a portfolio for the contributors. The designs could be rated.",
    externalLink: "",
    filter: "fs",
    featured: false,
  },
];

export const getFeaturedPortfolios = () =>
  portfolio.filter((work) => work.featured);
export const getFeaturedPortfolioFilters = () => {
  const featured = getFeaturedPortfolios();
  const featuredFilters = featured.map((filter) => filter.filter);
  let uniqueFilters = [...new Set(featuredFilters)];
  return portfolioFilters.filter((filter) => {
    return filter.keys.some((key) => uniqueFilters.includes(key));
  });
};

export default portfolio;
