import React from "react";
import { Link } from "react-router-dom";
import styles from "./index.module.css";

const Paginator = ({
  maxNumberOfPages,
  totalNumberOfItems,
  perPage,
  currentPage,
}) => {
  const requiredNumberOfPage = Math.ceil(totalNumberOfItems / perPage);
  const displayNumberOfPages =
    requiredNumberOfPage > maxNumberOfPages
      ? parseInt(maxNumberOfPages, 10)
      : requiredNumberOfPage;

  const pages = [...Array(displayNumberOfPages).keys()].map((index) => {
    return {
      label: index + 1,
      page: index + 1,
    };
  });

  return (
    <nav>
      <ul data-testid="paginator" className={styles.paginator}>
        {pages.length > 1 &&
          pages.map((page) => (
            <li
              data-testid={`paginator-container-${page.page}`}
              key={page.page}
              className={`${styles.paginatorItem} ${
                parseInt(currentPage, 10) === page.page
                  ? styles.paginatorItemSelected
                  : ""
              }`}
            >
              <Link
                data-testid={`paginator-link-${page.page}`}
                role="link"
                className={`${styles.paginatorItemLink} ${
                  parseInt(currentPage, 10) === page.page
                    ? styles.paginatorItemLinkSelected
                    : ""
                }`}
                to={`/blog?page=${page.page}`}
              >
                {page.label}
              </Link>
            </li>
          ))}
      </ul>
    </nav>
  );
};

export default Paginator;
