import React, { useEffect } from "react";
import Header from "../../Component/Header";
import Footer from "../../Component/Footer";
import roles from "../../Data/roles";
import CVEducation from "./CVEducation";
import CVHero from "./CVHero";
import CVStatement from "./CVStatement";
import CVSkills from "./CVSkills";
import CVWork from "./CVWork";

const CVPage = ({ title }) => {
  const [selectedRole, setSelectedRole] = React.useState(roles[0].id);

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <>
      <Header excludeSubMenu />
      <CVHero
        selectedRole={selectedRole}
        onClickHandler={(selectedRole) => {
          setSelectedRole(selectedRole);
        }}
      />
      <CVStatement selectedRole={selectedRole} />
      <CVSkills selectedRole={selectedRole} />
      <CVWork selectedRole={selectedRole} />
      <CVEducation />
      <Footer />
    </>
  );
};

export default CVPage;
