import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faBan } from "@fortawesome/free-solid-svg-icons";
import debounce from "lodash.debounce";
import Container from "../Container";
import styles from "./index.module.css";

function clearInput() {
  var searchTerm = document.getElementById("search");

  if (searchTerm.value !== "") {
    searchTerm.value = "";
  }
}

const SearchInput = ({ placeholder, onSearchHandler, onResetHandler }) => {
  const [hasTerm, setHasTerm] = useState(false);

  const debouncedSearch = React.useRef(
    debounce((term) => {
      onSearchHandler(term);
    }, 300)
  ).current;

  const searchInputHandler = (event) => {
    debouncedSearch(event.target.value);

    if (event.target.value) {
      setHasTerm(true);
    }
  };

  const resetSearch = () => {
    clearInput();
    setHasTerm(false);
    onResetHandler();
  };

  return (
    <Container classExtra={styles.searchContainer}>
      <FontAwesomeIcon
        icon={faSearch}
        className={`${styles.searchContainerIcon} ${styles.searchContainerSearchIcon}`}
      />
      <input
        id="search"
        data-testid="search-input"
        onChange={searchInputHandler}
        className={styles.searchContainerInput}
        type="text"
        placeholder={placeholder}
      />
      {hasTerm && (
        // TODO: This should be a button
        <FontAwesomeIcon
          data-testid="search-reset"
          icon={faBan}
          className={`${styles.searchContainerIcon} ${styles.searchContainerClearIcon}`}
          onClick={resetSearch}
        />
      )}
    </Container>
  );
};

export default SearchInput;
