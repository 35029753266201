import React from "react";
import styles from "./index.module.css";

const Timeline = ({ items }) => (
  <div className={styles.timeline}>
    <div className={styles.timelineInner}>
      {items.map((item, index) => {
        return (
          <div key={index} className={styles.timelineItem}>
            <div className={styles.timelineContent}>
              <div className={styles.timelineDate}>
                <p className={styles.timelineDateDisplay}>{item.month}</p>
                <p className={styles.timelineDateDisplay}>
                  <span>{item.year}</span>
                </p>
              </div>
              <h2 className={styles.timelineTitle}>{item.title}</h2>
              <p>{item.details}</p>
            </div>
          </div>
        );
      })}
    </div>
  </div>
);

export default Timeline;
