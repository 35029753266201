import React, { useEffect } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import NotFoundPage from "../NotFound";
import Header from "../../Component/Header";
import Footer from "../../Component/Footer";
import Hero from "../../Component/Hero";
import Title from "../../Component/Title";
import HomeAbout from "../Home/HomeAbout";
import HomeServices from "../Home/HomeServices";
import MusicPages from "../Music/index";

const AboutPage = ({ title }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <>
      <Header excludeSubMenu />
      <Hero type="cv" short>
        <Title withMargin>About</Title>
      </Hero>
      <HomeAbout />
      <HomeServices />
      <Footer />
    </>
  );
};

const AboutPages = ({ title }) => {
  let match = useRouteMatch();

  return (
    <>
      <Switch>
        <Route path={`${match.path}/music`}>
          <MusicPages title={`${title} | about | music`} />
        </Route>
        <Route>
          <AboutPage exact title={`${title} | about`} />
        </Route>
        <Route>
          <NotFoundPage title={`${title} | 404`} />
        </Route>
      </Switch>
    </>
  );
};

export default AboutPages;
