import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./index.module.css";

const VerticalSubNav = ({ tabs, onClick }) => {
  const [selectedTab, setSelectedTab] = React.useState(Object.keys(tabs)[0]);

  return (
    <ul className={styles.verticalNav} role="tablist">
      {Object.keys(tabs).map((key, i) => (
        <li
          role="presentation"
          className={`${styles.verticalNavItem} ${
            i !== Object.keys(tabs).length - 1
              ? styles.verticalNavItemTrail
              : ""
          }`}
          key={key}
        >
          <span
            data-testid={`tooltip-${key}`}
            className={`${styles.verticalNavTooltip} ${
              selectedTab === key ? "" : "hidden"
            }`}
          >
            {tabs[key].tooltip}
          </span>
          <button
            className={`${styles.verticalNavBtn} ${
              selectedTab === key ? styles.verticalNavBtnSelected : ""
            }`}
            role="tab"
            aria-label={tabs[key].tooltip}
            data-testid={key}
            onClick={() => {
              setSelectedTab(key);
              onClick(key);
            }}
          >
            <FontAwesomeIcon icon={tabs[key].icon} />
          </button>
        </li>
      ))}
    </ul>
  );
};

export default VerticalSubNav;
