import React from "react";
import SubTitle from "../../Component/SubTitle";
import styles from "./index.module.css";

export const PromoBlockContainer = ({ children, classExtra = "" }) => (
  <div className={`${styles.promoBlock} ${classExtra}`}>{children}</div>
);

export const PromoBlockContent = ({ children, classExtra = "" }) => (
  <div className={`${styles.promoBlockContent} ${classExtra}`}>{children}</div>
);

export const PromoBlockPanel = ({ children, ...nativeProps }) => (
  <div className={styles.promoBlockPanel} {...nativeProps}>
    {children}
  </div>
);

export const PromoBlockFlex = ({ children }) => (
  <div className={styles.promoBlockFlex}>{children}</div>
);

export const PromoBlockImg = ({ img, imgAlt }) => (
  <div className={styles.promoBlockImg}>
    <img src={img} alt={imgAlt} />
  </div>
);

export const PromoBlockText = ({ children }) => (
  <p className={styles.promoBlockText}>{children}</p>
);

const PromoBlock = ({ title, img, imgAlt, statement }) => (
  <PromoBlockContainer>
    <PromoBlockContent classExtra={styles.promoBlockContentNoNav}>
      <PromoBlockPanel>
        <SubTitle title={title} />
        <PromoBlockFlex>
          <PromoBlockImg img={img} imgAlt={imgAlt} />
          <div>
            <PromoBlockText>{statement}</PromoBlockText>
          </div>
        </PromoBlockFlex>
      </PromoBlockPanel>
    </PromoBlockContent>
  </PromoBlockContainer>
);

export default PromoBlock;
