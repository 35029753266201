import React from "react";
import {
  faLaptop,
  faTasks,
  faRocket,
  faCode,
  faCubes,
  faDatabase,
  faBook,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import Container from "../../Component/Container";
import Blocks from "../../Component/Blocks";
import PieChart from "../../Component/PieChart";
import Section from "../../Component/Section";
import SectionTitle from "../../Component/SectionTitle";
import {
  languageSkills,
  frameworkSkills,
  databaseSkills,
  toolSkills,
  methodologySkills,
  applicationSkills,
  managementSkills,
} from "../../Data/skills";

const CVSkills = ({ selectedRole }) => {
  const includeContentHandler = (content) =>
    content.roles.indexOf(selectedRole) !== -1;

  return (
    <Section grey>
      <Container>
        <SectionTitle title="My Skills" icon={faRocket} />
        <Blocks
          contentItems={skills}
          size="large"
          noHover={true}
          includeContentHandler={includeContentHandler}
        />
      </Container>
    </Section>
  );
};

const { innerWidth: screenWidth } = window;

const pieWidth = screenWidth < 900 ? 300 : 340;
const pieHeight = pieWidth / 1.7;

const convertSkillsPie = (skills) => {
  return skills.map((skill) => {
    const value = skill.experience + skill.proficiency + skill.relevance;
    return {
      label: `${skill.skillLabel} (${value})`,
      value,
    };
  });
};

const skills = [
  {
    heading: "Languages",
    icon: faCode,
    content: (
      <PieChart
        id="skills-language"
        width={pieWidth}
        height={pieHeight}
        margin={5}
        data={convertSkillsPie(languageSkills)}
      />
    ),
    roles: ["se"],
  },
  {
    heading: "Frameworks",
    icon: faCubes,
    content: (
      <PieChart
        id="skills-framework"
        width={pieWidth}
        height={pieHeight}
        margin={5}
        data={convertSkillsPie(frameworkSkills)}
      />
    ),
    roles: ["se"],
  },
  {
    heading: "Databases",
    icon: faDatabase,
    content: (
      <PieChart
        id="skills-database"
        width={pieWidth}
        height={pieHeight}
        margin={5}
        data={convertSkillsPie(databaseSkills)}
      />
    ),
    roles: ["se"],
  },
  {
    heading: "Libraries",
    icon: faBook,
    content: (
      <PieChart
        id="skills-library"
        width={pieWidth}
        height={pieHeight}
        margin={5}
        data={convertSkillsPie(toolSkills)}
      />
    ),
    roles: ["se"],
  },
  {
    heading: "Methodologies",
    icon: faTasks,
    content: (
      <PieChart
        id="skills-methods"
        width={pieWidth}
        height={pieHeight}
        margin={5}
        data={convertSkillsPie(methodologySkills)}
      />
    ),
    roles: ["se", "sm"],
  },
  {
    heading: "Management",
    icon: faUsers,
    content: (
      <PieChart
        id="skills-management"
        width={pieWidth}
        height={pieHeight}
        margin={5}
        data={convertSkillsPie(managementSkills)}
      />
    ),
    roles: ["sm", "tl"],
  },
  {
    heading: "Applications",
    icon: faLaptop,
    content: (
      <PieChart
        id="skills-applications"
        width={pieWidth}
        height={pieHeight}
        margin={5}
        data={convertSkillsPie(applicationSkills)}
      />
    ),
    roles: ["se", "sm", "tl"],
  },
];

export default CVSkills;
