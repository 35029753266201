import React from "react";
import styles from "./index.module.css";

const Period = ({ img, imgAlt, title, period, children }) => (
  <div className={styles.periodContainer}>
    <div className={styles.period}>
      <div className={styles.periodRow}>
        <img src={img} alt={imgAlt} className={styles.periodRowImg} />
      </div>
      <div className={styles.periodRow}>
        <h2 className={styles.periodTitle}>{title}</h2>
        <div className={styles.periodRange}>
          {/* TODO: pass dates and format */}
          {period}
        </div>
      </div>
    </div>
    {children && <div>{children}</div>}
    <hr className={styles.periodSeparator} />
  </div>
);

export default Period;
