import React from "react";
import ButtonRound from "../../Component/ButtonRound";
import ContainerInfo from "../../Component/ContainerInfo";
import Hero from "../../Component/Hero";
import Title from "../../Component/Title";
import roles from "../../Data/roles";
import styles from "./CVHero.module.css";

const CVHero = ({ selectedRole, onClickHandler }) => {
  return (
    <Hero type="cv">
      <div className={styles.cvIntro}>
        <ContainerInfo>
          <Title>CV</Title>
          <h2 className={styles.subTitle}>
            <span className={styles.roleContainer}>
              <b className={styles.roleContainerItem}>
                Select the role you are hiring for
              </b>
            </span>
          </h2>
        </ContainerInfo>
        <div className={styles.roleSelector}>
          {roles.map((role) => (
            <div key={role.id} className={styles.role}>
              <ButtonRound
                icon={role.icon}
                selected={selectedRole === role.id}
                onClick={() => onClickHandler(role.id)}
              />
              <p className={styles.roleTitle}>{role.label}</p>
            </div>
          ))}
        </div>
      </div>
    </Hero>
  );
};

export default CVHero;
