import React from "react";
import styles from "./index.module.css";

const ProgressBars = ({ mapper }) => (
  <>
    {Object.keys(mapper).map((key) => (
      <div className={styles.progressBarItem} key={key}>
        <h4>{key}</h4>
        <div className={styles.progress}>
          <div
            className={styles.progressBar}
            role="progressbar"
            aria-valuenow={mapper[key]}
            aria-valuemin="0"
            aria-valuemax="100"
            percentage={mapper[key]}
            style={{ width: `${mapper[key]}%` }}
          >
            <span className={styles.progressBarLabel}>{mapper[key]}%</span>
          </div>
        </div>
      </div>
    ))}
  </>
);

export default ProgressBars;
