import React from "react";
import Container from "../../Component/Container";
import PromoBlock from "../../Component/PromoBlock";
import Section from "../../Component/Section";
import roles from "../../Data/roles";

const CVStatement = ({ selectedRole }) => {
  const roleDetails = roles.filter((role) => role.id === selectedRole)[0];

  return (
    <Section classExtra="about">
      <Container>
        <PromoBlock
          title="Personal Statement"
          img={roleDetails.image}
          imgAlt="About me"
          statement={roleDetails.statement}
        />
      </Container>
    </Section>
  );
};

export default CVStatement;
