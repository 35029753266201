import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./index.module.css";

const Button = ({ color, icon, label, ...nativeProps }) => {
  let colorClass;

  switch (color) {
    case "green":
      colorClass = styles.btnGreen;
      break;

    case "white":
      colorClass = styles.btnWhite;
      break;

    default:
      break;
  }

  return (
    <button
      type="button"
      className={`${styles.btn} ${colorClass}`}
      {...nativeProps}
    >
      <FontAwesomeIcon icon={icon} className={styles.btnIcon} />
      {label}
    </button>
  );
};

export default Button;
