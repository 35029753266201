import React from "react";
// Keep JQuery above OwlCarousel
import "../../Utils/jquery-loader";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

// https://github.com/laurenchen0631/react-owl-carousel
// TODO: Contribute to remove that warning in console
const Carousel = React.forwardRef(({ children }, ref) => {
  return (
    <OwlCarousel className="owl-theme" items={1} margin={10} ref={ref} loop>
      {children}
    </OwlCarousel>
  );
});

export default Carousel;
