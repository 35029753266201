import React from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import Container from "../../Component/Container";
import Period from "../../Component/Period";
import Section from "../../Component/Section";
import SectionTitle from "../../Component/SectionTitle";
import work from "../../Data/work";
import styles from "./CVWork.module.css";

const CVWork = ({ selectedRole }) => {
  return (
    <Section>
      <Container>
        <SectionTitle title="Work" icon={faBriefcase} />
        <div>
          {work.map((work, i) => (
            <Period
              key={i}
              img={work.companyLogo}
              imgAlt={work.companyName}
              title={work.jobTitle}
              period={work.period}
            >
              <div className={styles.achievementContainer}>
                <p className={styles.achievementTitle}>Achievements</p>
                <ul className={styles.achievements}>
                  {work.achievements.map((achievement, i) => {
                    if (achievement.roles.indexOf(selectedRole) !== -1) {
                      return <li key={i}>{achievement.description}</li>;
                    }

                    return "";
                  })}
                </ul>
              </div>
            </Period>
          ))}
        </div>
      </Container>
    </Section>
  );
};

export default CVWork;
