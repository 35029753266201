import React from "react";
import Button from "../Button";
import ButtonRow from "../ButtonRow";
import Card from "../Card";
import styles from "./index.module.css";

const FilterGrid = ({ filters, cards }) => {
  const [selectedFilter, setSelectedFilter] = React.useState(filters[0]);

  const applyFilter = (filter) => {
    setSelectedFilter(filter);
  };

  return (
    <>
      <ButtonRow>
        {filters.map((filter) => {
          return (
            <Button
              key={filter.id}
              data-testid={`btn-${filter.id}`}
              label={filter.label}
              color={selectedFilter.id === filter.id && "green"}
              icon={filter.icon}
              onClick={() => {
                applyFilter(filter);
              }}
            />
          );
        })}
      </ButtonRow>
      <div className={styles.cardContainer}>
        {cards.map((card) => {
          if (selectedFilter.keys.indexOf(card.filter) === -1) {
            return "";
          }

          return (
            <Card
              key={card.id}
              card={{
                id: card.id,
                img: card.img,
                imgAlt: card.imgAlt,
                title: card.title,
                info: card.info,
                externalLink: card.externalLink,
                category: findCategory(filters, card.filter),
              }}
            />
          );
        })}
      </div>
    </>
  );
};

const findCategory = (filters, key) => {
  const categoryFilter = filters.filter(
    (filter) => filter.keys.includes(key) && filter.id !== "all"
  );

  if (categoryFilter.length === 0) {
    return "";
  }

  return categoryFilter[0].label;
};

export default FilterGrid;
