import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft, faStar } from "@fortawesome/free-solid-svg-icons";
import Carousel from "../../Component/Carousel";
import Container from "../../Component/Container";
import Section from "../../Component/Section";
import SectionTitle from "../../Component/SectionTitle";
import testimonials from "../../Data/testimonials";
import styles from "./HomeTestimonials.module.css";

const HomeTestimonials = () => {
  const testimonialsRef = React.createRef();

  return (
    <Section id="testimonials" classExtra={styles.testimonials}>
      <Container>
        <SectionTitle
          title="Client's Testimonials"
          icon={faStar}
          color="white"
        />
        <Carousel ref={testimonialsRef}>
          {testimonials.map((testimonial, key) => (
            <div key={key} className={styles.testimonialContainer}>
              <div className={styles.testimonial}>
                <div className={styles.testimonialContent}>
                  <FontAwesomeIcon
                    icon={faQuoteLeft}
                    className={styles.testimonialIcon}
                  />
                  <p>{testimonial.quote}</p>
                </div>
                <div className={styles.testimonialInfo}>
                  <div className={styles.testimonialSource}>
                    <img
                      src={testimonial.img}
                      alt={testimonial.source}
                      className={`img--round ${styles.testimonialSrcIcon}`}
                    />
                  </div>
                </div>
                <div className={styles.testimonialSource}>
                  <h6 className={styles.testimonialSourceContent}>
                    {testimonial.source}
                    <span className={styles.testimonialSourceSubContent}>
                      {testimonial.title}
                    </span>
                    <span className={styles.testimonialSourceSubContent}>
                      {testimonial.company}
                    </span>
                  </h6>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </Container>
    </Section>
  );
};

export default HomeTestimonials;
