import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./index.module.css";

const MenuItem = ({ id, icon, label, onClickHandler, classExtra }) => {
  return (
    <li
      data-testid={`menu-item-${id}`}
      className={`${styles.menuItem} ${classExtra}`}
    >
      <button
        type="button"
        className={styles.menuItemLink}
        onClick={onClickHandler}
      >
        <span className={styles.menuItemLinkIcon}>
          <FontAwesomeIcon data-testid="menu-item-icon" icon={icon} />
        </span>
        {label}
      </button>
    </li>
  );
};

export const MenuItemLogo = () => (
  <div className={styles.menuItemLogo}>
    <a className={styles.menuItemLogoLink} href="/">
      Nick Bennett
    </a>
  </div>
);

export const MenuItemToggle = ({ icon, onClickHandler }) => (
  <div className={styles.menuItemToggle}>
    <button className={styles.menuItemLink} onClick={onClickHandler}>
      <span className={styles.menuItemToggleLabel}>MENU</span>
      <FontAwesomeIcon data-testid="menu-toggle-icon" icon={icon} />
    </button>
  </div>
);

export default MenuItem;
