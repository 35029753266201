const skills = {
  "LAMP stack development": 90,
  "AWS - EC2": 60,
  Docker: 55,
  "React/Angular/JavaScript": 70,
  "Responsive web development": 65,
  "Command line tools": 80,
  "Scrum master": 85,
  "Team lead": 70,
};

export const languageSkills = [
  {
    skillLabel: "PHP",
    experience: 10,
    proficiency: 9,
    relevance: 9,
  },
  {
    skillLabel: "Ruby",
    experience: 6,
    proficiency: 6,
    relevance: 6,
  },
  {
    skillLabel: "Perl",
    experience: 6,
    proficiency: 6,
    relevance: 2,
  },
  {
    skillLabel: "Javascript",
    experience: 8,
    proficiency: 8,
    relevance: 8,
  },
  {
    skillLabel: "HTML",
    experience: 9,
    proficiency: 9,
    relevance: 7,
  },
  {
    skillLabel: "CSS",
    experience: 6,
    proficiency: 6,
    relevance: 8,
  },
  {
    skillLabel: "Bash",
    experience: 7,
    proficiency: 7,
    relevance: 8,
  },
];

export const frameworkSkills = [
  {
    skillLabel: "Rails 6",
    experience: 6,
    proficiency: 6,
    relevance: 7,
  },
  {
    skillLabel: "ZF 1",
    experience: 7,
    proficiency: 6,
    relevance: 7,
  },
  {
    skillLabel: "ZF 2",
    experience: 8,
    proficiency: 7,
    relevance: 8,
  },
  {
    skillLabel: "Angular",
    experience: 7,
    proficiency: 8,
    relevance: 9,
  },
  {
    skillLabel: "Symfony",
    experience: 6,
    proficiency: 6,
    relevance: 7,
  },
  {
    skillLabel: "Cake",
    experience: 6,
    proficiency: 6,
    relevance: 4,
  },
  {
    skillLabel: "Silex",
    experience: 3,
    proficiency: 3,
    relevance: 1,
  },
  {
    skillLabel: "Wordpress",
    experience: 7,
    proficiency: 3,
    relevance: 7,
  },
];

export const databaseSkills = [
  {
    skillLabel: "PostgreSQL",
    experience: 6,
    proficiency: 7,
    relevance: 7,
  },
  {
    skillLabel: "MySQL",
    experience: 9,
    proficiency: 9,
    relevance: 7,
  },
  {
    skillLabel: "MSSQL",
    experience: 6,
    proficiency: 4,
    relevance: 3,
  },
  {
    skillLabel: "MS Access",
    experience: 5,
    proficiency: 4,
    relevance: 1,
  },
  {
    skillLabel: "MongoDB",
    experience: 4,
    proficiency: 3,
    relevance: 6,
  },
  {
    skillLabel: "Oracle",
    experience: 4,
    proficiency: 4,
    relevance: 1,
  },
];

export const toolSkills = [
  {
    skillLabel: "React",
    experience: 7,
    proficiency: 7,
    relevance: 8,
  },
  {
    skillLabel: "TypeScript",
    experience: 6,
    proficiency: 6,
    relevance: 8,
  },
  {
    skillLabel: "React Testing Library",
    experience: 3,
    proficiency: 4,
    relevance: 7,
  },
  {
    skillLabel: "ReactQuery",
    experience: 5,
    proficiency: 7,
    relevance: 7,
  },
  {
    skillLabel: "JQuery",
    experience: 9,
    proficiency: 9,
    relevance: 9,
  },
  {
    skillLabel: "SASS",
    experience: 3,
    proficiency: 2,
    relevance: 9,
  },
  {
    skillLabel: "NodeJS",
    experience: 7,
    proficiency: 6,
    relevance: 7,
  },
  {
    skillLabel: "Karma",
    experience: 7,
    proficiency: 7,
    relevance: 8,
  },
  {
    skillLabel: "PHPUnit",
    experience: 8,
    proficiency: 8,
    relevance: 9,
  },
  {
    skillLabel: "Socket.io",
    experience: 7,
    proficiency: 4,
    relevance: 8,
  },
];

export const methodologySkills = [
  {
    skillLabel: "Scrum",
    experience: 7,
    proficiency: 7,
    relevance: 7,
  },
  {
    skillLabel: "Kanban",
    experience: 6,
    proficiency: 5,
    relevance: 6,
  },
  {
    skillLabel: "TDD",
    experience: 7,
    proficiency: 7,
    relevance: 7,
  },
  {
    skillLabel: "Backlogs",
    experience: 7,
    proficiency: 7,
    relevance: 7,
  },
  {
    skillLabel: "Cross Functionality",
    experience: 5,
    proficiency: 6,
    relevance: 8,
  },
  {
    skillLabel: "Poker Planning",
    experience: 7,
    proficiency: 8,
    relevance: 9,
  },
];

export const applicationSkills = [
  {
    skillLabel: "Jira",
    experience: 7,
    proficiency: 7,
    relevance: 8,
  },
  {
    skillLabel: "Bamboo",
    experience: 6,
    proficiency: 5,
    relevance: 8,
  },
  {
    skillLabel: "Stash",
    experience: 8,
    proficiency: 8,
    relevance: 8,
  },
  {
    skillLabel: "Confluence",
    experience: 8,
    proficiency: 8,
    relevance: 8,
  },
  {
    skillLabel: "OnTime",
    experience: 7,
    proficiency: 8,
    relevance: 4,
  },
  {
    skillLabel: "Salesforce",
    experience: 6,
    proficiency: 6,
    relevance: 3,
  },
];

export const managementSkills = [
  {
    skillLabel: "MI Reporting",
    experience: 8,
    proficiency: 8,
    relevance: 5,
  },
  {
    skillLabel: "Appraisals",
    experience: 6,
    proficiency: 5,
    relevance: 3,
  },
  {
    skillLabel: "Lead Meetings",
    experience: 8,
    proficiency: 9,
    relevance: 8,
  },
  {
    skillLabel: "Define Processes",
    experience: 7,
    proficiency: 7,
    relevance: 8,
  },
  {
    skillLabel: "Process adoption",
    experience: 7,
    proficiency: 8,
    relevance: 8,
  },
  {
    skillLabel: "Removed Blockers",
    experience: 7,
    proficiency: 7,
    relevance: 9,
  },
  {
    skillLabel: "Performance reviews",
    experience: 6,
    proficiency: 7,
    relevance: 4,
  },
  {
    skillLabel: "Project lead",
    experience: 6,
    proficiency: 8,
    relevance: 6,
  },
  {
    skillLabel: "Conflict Resolution",
    experience: 6,
    proficiency: 8,
    relevance: 4,
  },
  {
    skillLabel: "Documentation",
    experience: 7,
    proficiency: 8,
    relevance: 9,
  },
  {
    skillLabel: "Business Analysis",
    experience: 6,
    proficiency: 8,
    relevance: 4,
  },
  {
    skillLabel: "Mentoring",
    experience: 8,
    proficiency: 8,
    relevance: 9,
  },
];

export default skills;
