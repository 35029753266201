import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArchway,
  faFutbol,
  faCode,
  faBrain,
  faLaptopCode,
  faGlobeEurope,
  faServer,
  faAngry,
  faEdit,
  faFileCode,
  faNetworkWired,
  faStream,
  faClipboardList,
} from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";
import styles from "./Categories.module.css";

const Categories = ({ categories }) => {
  return (
    <div className={styles.categoryContainer}>
      <strong>Categories:</strong>
      <div className={styles.iconContainer}>
        {categories.length > 0 &&
          categories.map((category) => {
            let icon = "";

            switch (category.slug) {
              case "archetecture":
                icon = faArchway;
                break;

              case "football":
                icon = faFutbol;
                break;

              case "html5":
                icon = faCode;
                break;

              case "ideas":
                icon = faBrain;
                break;

              case "javascript":
                icon = faLaptopCode;
                break;

              case "php":
                icon = faServer;
                break;

              case "rant":
                icon = faAngry;
                break;

              case "reviews":
                icon = faEdit;
                break;

              case "scripts":
                icon = faFileCode;
                break;

              case "technology":
                icon = faNetworkWired;
                break;

              case "agile":
                icon = faStream;
                break;

              case "project-management":
                icon = faClipboardList;
                break;

              default:
                icon = faGlobeEurope;
                break;
            }

            return (
              <button
                key={category.slug}
                data-tooltip-id="category-tooltip"
                data-tooltip-content={category.name}
                className={styles.buttonTooltip}
              >
                <FontAwesomeIcon
                  icon={icon}
                  className={styles.categoryIcon}
                  size="2x"
                />
              </button>
            );
          })}

        <Tooltip id="category-tooltip" />
      </div>
    </div>
  );
};

export default Categories;
