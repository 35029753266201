import cutover from "../Assets/Images/work/companies/cutover.png";
import goHenry from "../Assets/Images/work/companies/go-henry.png";
import ajBell from "../Assets/Images/work/companies/ajbell.png";
import ii from "../Assets/Images/work/companies/interactive_investor.png";
import rjis from "../Assets/Images/work/companies/rjis_logo.png";
import dl from "../Assets/Images/work/companies/digital_look.png";
import nomad from "../Assets/Images/work/companies/nomad_black.png";

const work = [
  {
    companyLogo: cutover,
    companyName: "Cutover",
    jobTitle: "Lead Engineer",
    period: "July 2019 - Current",
    achievements: [
      {
        description:
          "Migrated admin sections from AngularJS to React within the core app.",
        roles: ["se"],
      },
      {
        description:
          "Developed key app features using AngularJS and Rails API.",
        roles: ["se"],
      },
      {
        description:
          "Lead the team to successfully deliver several core app initiatives.",
        roles: ["tl"],
      },
      {
        description:
          "Mentored engineers at all levels to help them move to the next level of their career.",
        roles: ["tl"],
      },
      {
        description:
          "Gave technical oversight and support to the team for several large projects.",
        roles: ["se"],
      },
      {
        description:
          "Introduced performance testing to the project delivery process.",
        roles: ["sm", "tl"],
      },
      {
        description:
          "Organised 1-1's, performance reviews and OKRs for the team.",
        roles: ["tl"],
      },
      {
        description:
          "Helped transition the squad from chaos to calm after jarring alterations imposed by the company.",
        roles: ["tl"],
      },
      {
        description:
          "Worked with the project manager and delivery manager closely to ensure a predictable output from the team.",
        roles: ["sm"],
      },
      {
        description: "Presented squad updates to the entire organisation.",
        roles: ["se", "tl"],
      },
      {
        description: "Raised organisation issues with key stakeholders.",
        roles: ["tl", "sm"],
      },
    ],
  },
  {
    companyLogo: goHenry,
    companyName: "GoHenry",
    jobTitle: "Software Engineer",
    period: "July 2018 - July 2019",
    achievements: [
      {
        description: "Helped shape the Agile processes.",
        roles: ["sm"],
      },
      {
        description:
          "Re-wrote the back office customer service tool using Angular 6 and Angular material.",
        roles: ["se", "sm", "tl"],
      },
      {
        description:
          "Integrated with 3rd parties including FIS and Adyen to enhance and extend existing backend APIs.",
        roles: ["se", "sm", "tl"],
      },
      {
        description: "Cleared the backlog of tickets for the core API.",
        roles: ["se", "sm", "tl"],
      },
    ],
  },
  {
    companyLogo: ajBell,
    companyName: "A J Bell",
    jobTitle: "Software Engineer",
    period: "June 2016 - June 2018",
    achievements: [
      {
        description: "Gained ITIL Foundation qualification.",
        roles: ["sm"],
      },
      {
        description:
          "Delivered new business applications for ISA, SIPP, and Dealing accounts.",
        roles: ["se", "sm", "tl"],
      },
      {
        description:
          "Set up PHPUnit and Protractor and introduced the team to the concepts.",
        roles: ["se", "sm", "tl"],
      },
      {
        description:
          "Carried out Experian calls via SOAP to carry out identity checks on applicants.",
        roles: ["se", "sm", "tl"],
      },
      {
        description:
          "Used Post Code Anywhere to validate address and banking information.",
        roles: ["se", "sm", "tl"],
      },
      {
        description:
          "Ensured responsive behavior of the application form using Twitter Bootstrap.",
        roles: ["se", "sm", "tl"],
      },
      {
        description:
          "Ensured code maintainability by introducing Handlebars to the project.",
        roles: ["se", "sm", "tl"],
      },
      {
        description: "Used PDFTK to add dynamic data to PDF forms.",
        roles: ["se", "sm", "tl"],
      },
    ],
  },
  {
    companyLogo: ii,
    companyName: "Interactive Investor",
    jobTitle: "Software Engineer",
    period: "April 2014 - June 2016",
    achievements: [
      {
        description: "Delivered closed beta UX revamp project on time.",
        roles: ["sm"],
      },
      {
        description:
          "Completed re-worked company processes to protect the development team.",
        roles: ["sm"],
      },
      {
        description:
          "Built the complex trading functionality from scratch using Angular, NodeJS and Socket.io.",
        roles: ["se", "tl"],
      },
      {
        description:
          "Built the complex registration functionality using ZF2, Angular directives (for form elements), and hosted card capture for 3D secure card information.",
        roles: ["se", "tl"],
      },
      {
        description:
          "Delivered project Solomon, a single page app using Angular that allows users to choose Funds for watch-lists.",
        roles: ["se", "tl"],
      },
      {
        description:
          "Delivered white label trading platform for The Telegraph.",
        roles: ["se", "tl"],
      },
      {
        description:
          "Helped re-write existing APIs as microservices using PHP and managed via Packagist.",
        roles: ["se", "tl"],
      },
      {
        description:
          "Angular directives (for form elements), and hosted card capture for 3D secure card information.",
        roles: ["se"],
      },
      {
        description: "Supported existing RESTful APIs.",
        roles: ["se"],
      },
      {
        description: "Created new custom PHP packages.",
        roles: ["se"],
      },
      {
        description: "Adding features to the new UX site.",
        roles: ["se"],
      },
      {
        description: "Bug fixing.",
        roles: ["se"],
      },
    ],
  },
  {
    companyLogo: rjis,
    companyName: "Raymond James Investment Services",
    jobTitle: "Team lead",
    period: "October 2008 - April 2014",
    achievements: [
      {
        description:
          "Built the bespoke MVC framework on which all RJIS tools are built.",
        roles: ["se"],
      },
      {
        description:
          "Built core operational scripts using PHP5 and Shell scripts for code deployment, error reporting, database backup, and management reporting.",
        roles: ["se"],
      },
      {
        description:
          "Architected and developed Investor Access, a secure access portal for clients to view their cash and stock holdings.",
        roles: ["se"],
      },
      {
        description:
          "Architected and developed Cash Account, a transactional system supporting the safe custody of client money.",
        roles: ["se"],
      },
      {
        description: "Helped deliver the Payroll project using Scrum.",
        roles: ["sm"],
      },
      {
        description: "Created work stream processes using OnTime and Git Flow.",
        roles: ["sm", "tl"],
      },
      {
        description:
          "Introduced and promoted Agile principles amongst the team.",
        roles: ["sm"],
      },
    ],
  },
  {
    companyLogo: dl,
    companyName: "Digital Look",
    jobTitle: "Software Engineer",
    period: "April 2006 - October 2008",
    achievements: [
      {
        description:
          "Built the personal finance section of the site, using XML feeds from product providers.",
        roles: ["se", "sm", "tl"],
      },
      {
        description:
          "Built the i-Look section of the site which included customisable widgets using AJAX with jQuery.",
        roles: ["se", "sm", "tl"],
      },
      {
        description: "Created a shopping cart with World Pay integration.",
        roles: ["se", "sm", "tl"],
      },
      {
        description:
          "Developed a white label version of the site for international expansion.",
        roles: ["se", "sm", "tl"],
      },
      {
        description:
          "Incorporate research functionality into a new trading platform http://investoredition.marketmaker.co.uk/",
        roles: ["se", "sm", "tl"],
      },
    ],
  },
  {
    companyLogo: nomad,
    companyName: "Nomad",
    jobTitle: "Software Engineer",
    period: "July 2004 - April 2006",
    achievements: [
      {
        description:
          "Built client management desktop application using .NET and Visual Studio for a local accountancy firm.",
        roles: ["se", "sm", "tl"],
      },
      {
        description:
          "Built a complex CMS system from scratch using ASP.Net and Microsoft Access for Axel.",
        roles: ["se", "sm", "tl"],
      },
      {
        description:
          "Developed several online retails sites using PHP OSCommerce.",
        roles: ["se", "sm", "tl"],
      },
    ],
  },
];

export default work;
