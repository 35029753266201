import React, { useEffect } from "react";
import * as d3 from "d3";

function drawChart(id, width, height, margin, data, colorScale) {
  // Remove the old svg
  d3.select(`#${id}`).select("svg").remove();

  // Create new svg
  const svg = d3
    .select(`#${id}`)
    .append("svg")
    .attr("width", width)
    .attr("height", height);

  // Group 1 - Pie
  const g = svg
    .append("g")
    // Positioning
    .attr("transform", `translate(${(width / 4) * 3}, ${height / 2})`);

  // Half for keys and half for pie chart
  // 500/4 - 125
  // |----|
  const outerRadius = width / 4 - margin;
  const arcGenerator = d3.arc().innerRadius(0).outerRadius(outerRadius);
  const tooManyKeys = data.length * 20 + margin > height;
  const keyLineHeight = tooManyKeys ? 16 : 20;
  const fontSize = tooManyKeys ? 9 : 14;

  const pieGenerator = d3
    .pie()
    .padAngle(0)
    .value((d) => d.value);

  const arc = g.selectAll().data(pieGenerator(data)).enter();

  // Append arcs
  arc
    .append("path")
    // Path to be drawn
    .attr("d", arcGenerator)
    .style("fill", (_, i) => colorScale(i))
    .style("stroke", "#ffffff")
    .style("stroke-width", 0);

  // Vertical start point for the keys
  const verticalKeyPoint = (height - keyLineHeight * data.length) / 2;

  // Create the keys
  svg
    .append("g")
    .attr("transform", `translate(${margin}, ${verticalKeyPoint})`)
    .selectAll("text")
    .data(data)
    .enter()
    .append("text")
    .text((d) => {
      return d.label;
    })
    .style("fill", (_, i) => colorScale(i))
    .style("font-size", fontSize)
    .attr("y", (_, i) => {
      return keyLineHeight * (i + 1);
    });
}

function PieChart({ id, width, height, margin, data }) {
  // Define a colour range for each segment
  // https://observablehq.com/@d3/working-with-color
  const colorScale = d3
    .scaleSequential()
    .interpolator(d3.interpolateRgbBasis(d3.schemeCategory10))
    .domain([0, data.length]);

  useEffect(() => {
    drawChart(id, width, height, margin, data, colorScale);
  }, [id, width, height, margin, data, colorScale]);

  return <div id={id} />;
}

export default PieChart;
