import React from "react";
import Container from "../../Component/Container";
import Section from "../../Component/Section";
import SectionTitle from "../../Component/SectionTitle";
import Timeline from "../../Component/Timeline";
import { faUsers } from "@fortawesome/free-solid-svg-icons";

const MusicGigs = ({ gigs }) => {
  return (
    <Section grey>
      <Container>
        <SectionTitle title="Gigs" icon={faUsers} />
        <Timeline items={gigs} />
      </Container>
    </Section>
  );
};

export default MusicGigs;
