import React from "react";
import facebook from "../../Assets/Images/facebook.png";
import twitter from "../../Assets/Images/twitter.png";
import linkedin from "../../Assets/Images/linkedin.png";
import styles from "./index.module.css";

const SocialLink = ({ type }) => {
  let socialLink;

  switch (type) {
    case "facebook":
      socialLink = {
        id: "fb",
        href: "https://www.facebook.com/sufcboy",
        alt: "Facebook",
        img: facebook,
      };
      break;

    case "twitter":
      socialLink = {
        id: "tw",
        href: "https://twitter.com/sufcboy",
        alt: "Twitter",
        img: twitter,
      };
      break;

    default:
      socialLink = {
        id: "li",
        href: "https://www.linkedin.com/in/nickbennett1980/",
        alt: "LinkedIn",
        img: linkedin,
      };
      break;
  }

  return (
    <a
      data-testid={`social-link-${socialLink.id}`}
      className={styles.socialLinks}
      target="_blank"
      rel="noreferrer"
      href={socialLink.href}
    >
      <img
        data-testid={`social-link-img-${socialLink.id}`}
        src={socialLink.img}
        alt={socialLink.alt}
      />
    </a>
  );
};

export default SocialLink;
