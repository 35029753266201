import React from "react";
import { faMusic } from "@fortawesome/free-solid-svg-icons";
import Container from "../../Component/Container";
import Section from "../../Component/Section";
import SectionTitle from "../../Component/SectionTitle";
import styles from "./MusicAlbums.module.css";

const MusicAlbums = ({ albums }) => {
  return (
    <Section>
      <Container>
        <SectionTitle title="Music" icon={faMusic} />
        <div>
          {albums.map((album, index) => (
            <div key={index} className={styles.album}>
              <div className={styles.albumCover}>
                <img
                  src={album.img}
                  alt={album.name}
                  className={styles.albumCoverImg}
                />
                <div className="album-date">
                  <div className={styles.albumDateMonth}>
                    {album.releaseMonth}
                  </div>
                  <div className={styles.albumDateYear}>
                    {album.releaseYear}
                  </div>
                </div>
              </div>
              <div className={styles.albumInfo}>
                <h2>{album.name}</h2>
                <p>
                  <iframe
                    title={album.name}
                    src={album.link}
                    width="100%"
                    height="450"
                  ></iframe>
                </p>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </Section>
  );
};

export default MusicAlbums;
