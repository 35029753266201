import React, { useEffect } from "react";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import Container from "../../Component/Container";
import Period from "../../Component/Period";
import Section from "../../Component/Section";
import SectionTitle from "../../Component/SectionTitle";
import education from "../../Data/education";
import styles from "./CVEducation.module.css";

const CVEducation = () => {
  // We need to call this after the component is mounted
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "//cdn.youracclaim.com/assets/utilities/embed.js";
    script.async = true;

    document.body.appendChild(script);
  }, []);

  return (
    <Section grey>
      <Container>
        <SectionTitle title="Education" icon={faEdit} />
        <div className={styles.certContainer}>
          <div>
            <div
              data-iframe-width="150"
              data-iframe-height="270"
              data-share-badge-id="869bbeb5-209d-4c86-81d0-80fae5a5151e"
              data-share-badge-host="https://www.youracclaim.com"
            ></div>
          </div>
        </div>
        <div>
          {education.map((period, i) => (
            <Period
              key={i}
              img={period.img}
              imgAlt={period.imgAlt}
              title={period.title}
              period={period.period}
            />
          ))}
        </div>
      </Container>
    </Section>
  );
};

export default CVEducation;
