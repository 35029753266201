import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./index.module.css";

const SectionTitle = ({ title, icon, subTitle, color }) => (
  <div className={styles.sectionTitle}>
    <h2
      data-testid="title-content"
      className={`${styles.sectionTitleHeader} ${
        color === "white" && styles.sectionTitleWhite
      }`}
    >
      {title}
      <FontAwesomeIcon
        data-testid="title-icon"
        icon={icon}
        className={styles.sectionTitleIcon}
      />
    </h2>
    {subTitle && <p className={styles.sectionTitleSubTitle}>{subTitle}</p>}
  </div>
);

export default SectionTitle;
