import React, { useEffect } from "react";
import CVPage from "../CV/index";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { Switch, Route, useRouteMatch, Link } from "react-router-dom";
import NotFoundPage from "../NotFound";
import ButtonRow from "../../Component/ButtonRow";
import Button from "../../Component/Button";
import Container from "../../Component/Container";
import Header from "../../Component/Header";
import Footer from "../../Component/Footer";
import FilterGrid from "../../Component/FilterGrid";
import Hero from "../../Component/Hero";
import Section from "../../Component/Section";
import Title from "../../Component/Title";
import portfolios, { portfolioFilters } from "../../Data/portfolio";

const WorkPage = ({ title }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <>
      <Header excludeSubMenu />
      <Hero type="cv" short>
        <Title withMargin>WELCOME TO MY PORFOLIO</Title>
      </Hero>
      <Section>
        <Container>
          <FilterGrid filters={portfolioFilters} cards={portfolios} />
          <ButtonRow>
            <Link to="/work/cv">
              <Button
                data-testid="menu-toggle-icon"
                icon={faBriefcase}
                label="View CV"
                color="green"
              />
            </Link>
          </ButtonRow>
        </Container>
      </Section>
      <Footer />
    </>
  );
};

const WorkPages = ({ title }) => {
  let match = useRouteMatch();

  return (
    <>
      <Switch>
        <Route exact path={`${match.path}/cv`}>
          <CVPage title={`${title} | work | Interactive CV`} />
        </Route>
        <Route exact path={match.path}>
          <WorkPage title={`${title} | work`} />
        </Route>
        <Route>
          <NotFoundPage title={`${title} | 404`} />
        </Route>
      </Switch>
    </>
  );
};

export default WorkPages;
