import React from "react";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import Menu from "../Menu";
import { menuConfig } from "./data";
import { MenuItemLogo, MenuItemToggle } from "../MenuItem";
import Container from "../Container";
import styles from "./index.module.css";

const Header = ({ config = menuConfig, excludeSubMenu }) => {
  const [toggleIcon, setToggleIcon] = React.useState(faBars);
  const [displayMenu, setDisplayMenu] = React.useState(false);

  const toggleMenu = () => {
    setDisplayMenu(displayMenu ? false : true);
    setToggleIcon(toggleIcon === faBars ? faTimes : faBars);
  };

  return (
    <>
      <header id="header" className={styles.header}>
        <Container>
          <div className={styles.menuContainer}>
            <MenuItemLogo />
            {!excludeSubMenu && (
              <MenuItemToggle icon={toggleIcon} onClickHandler={toggleMenu} />
            )}
            <Menu
              config={config}
              excludeSubMenu={excludeSubMenu}
              displayMenu={displayMenu}
            />
          </div>
        </Container>
      </header>
    </>
  );
};

export default Header;
