import damon from "../Assets/Images/testimonials/damon_parsons.png";
import david from "../Assets/Images/testimonials/david_lewis.png";
import jamie from "../Assets/Images/testimonials/jamie_longstaff.png";
import tim from "../Assets/Images/testimonials/tim_huckle.png";

const testimonials = [
  {
    company: "AJ Bell",
    img: tim,
    source: "Tim Huckle",
    title: "Director of digital strategy",
    quote:
      "I've worked with a lot of software developers in the financial services sector over the last 25 years and very few have stood out like Nick in terms of his abilities and levels of professionalism. Nick worked within our digital strategy team for nearly two years and was instrumental in the delivery of several new features including customer on-boarding and our public API. A great team player who regularly interacted with senior business stakeholders to ensure a smooth and efficient process from requirements gathering through to delivery.",
  },
  {
    company: "Interactive Investor",
    img: david,
    source: "David Lewis",
    title: "Development Manager",
    quote:
      "Nick was always one of the most skilled, diligent and hardworking members in my team. His ability to communicate effectively at all levels sets him apart from his peers. Nick always embraced change and quickly picked up new skills whilst always maintaining high quality code. His personable and approachable nature afforded him to both lead and participate in the development team and communicate well with the wider business. I'd be happy to have Nick on my team anytime.",
  },
  {
    company: "Raymond James",
    img: jamie,
    source: "Jamie Longstaff",
    title: "Deputy head of IT",
    quote:
      "I had the pleasure of working with Nick for several years at Raymond James where he was the team lead for the software development function. As is the case with all the best software engineers I've worked with, Nick was always on the lookout for ways of working more effectively. He was consistently able to balance the benefits of introducing good governance and processes with the flexibility to deliver high-quality results quickly and on schedule. His consistently positive and enthusiastic approach made him both a valued manager and hands-on participant in every project he was involved in and I wouldn't hesitate to work with him again should the opportunity arise.",
  },
  {
    company: "Nomad",
    source: "Damon Parsons",
    title: "IT Manager",
    quote:
      "Nick initially joined us as a junior developer focusing on Microsoft technologies but it soon became apparent he's a capable and talented software engineer and quickly progressed into a more senior role responsible for a wide range of technologies and a diverse range of projects.",
    img: damon,
  },
];

export default testimonials;
