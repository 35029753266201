import React, { useState } from "react";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import Container from "../../Component/Container";
import ContainerInfo from "../../Component/ContainerInfo";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Hero from "../../Component/Hero";
import Loader from "../../Component/Loader";
import SearchInput from "../../Component/SearchInput";
import Section from "../../Component/Section";
import SectionTitle from "../../Component/SectionTitle";
import Title from "../../Component/Title";
import Paginator from "../../Component/Paginator";
import styles from "./index.module.css";
import BlogPost from "./BlogPost";
import usePosts from "../../Queries/posts";

const getCurrentPageFromQueryParams = (searchParams) => {
  let currentPage = 1;

  if (searchParams) {
    const queryParams = searchParams.replace("?", "").split("=");

    if (queryParams.indexOf("page") !== -1) {
      currentPage = parseInt(queryParams[queryParams.indexOf("page") + 1], 10);
    }
  }

  return currentPage;
};

const BlogPage = () => {
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState("");
  const currentPage = getCurrentPageFromQueryParams(location.search);
  const { isLoading, error, data } = usePosts(currentPage, searchTerm);

  return (
    <>
      <Header excludeSubMenu />
      <Hero short>
        <ContainerInfo classExtra={styles.searchContainer}>
          <Title>My Witterings</Title>
          <SearchInput
            placeholder="Search for blog post"
            onSearchHandler={(term) => {
              setSearchTerm(term);
            }}
            onResetHandler={() => {
              setSearchTerm("");
            }}
          />
        </ContainerInfo>
      </Hero>
      <Section classExtra={styles.sectionBlog}>
        <Container>
          <SectionTitle title="Latest" icon={faBook} />
          {isLoading && <Loader />}
          {!isLoading && !error && (
            <>
              {data.posts.map((post) => (
                <BlogPost key={post.id} post={post} />
              ))}
              {data.total > 0 ? (
                <Paginator
                  currentPage={currentPage}
                  totalNumberOfItems={data.total}
                  maxNumberOfPages={data.totalPages}
                  perPage={
                    data.totalPages
                      ? Math.ceil(data.total / data.totalPages)
                      : 0
                  }
                />
              ) : (
                <div className={styles.noResults}>
                  No results for{" "}
                  <span className={styles.noResultsQuote}>"{searchTerm}"</span>
                </div>
              )}
            </>
          )}
        </Container>
      </Section>
      <Footer />
    </>
  );
};

export default BlogPage;
