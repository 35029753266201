import React from "react";
import { faRocket } from "@fortawesome/free-solid-svg-icons";
import Blocks from "../../Component/Blocks";
import Container from "../../Component/Container";
import Section from "../../Component/Section";
import SectionTitle from "../../Component/SectionTitle";
import services from "../../Data/services";

const HomeServices = () => {
  return (
    <Section id="my-service" grey>
      <Container>
        <SectionTitle
          title="My Service"
          icon={faRocket}
          subTitle="Please review the services that I provide that could help you"
        />
        <Blocks contentItems={services} />
      </Container>
    </Section>
  );
};

export default HomeServices;
