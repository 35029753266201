import React from "react";
import styles from "./index.module.css";

const Container = ({ children, classExtra = "" }) => (
  <div data-testid="container" className={`${styles.container} ${classExtra}`}>
    {children}
  </div>
);

export default Container;
