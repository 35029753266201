import React from "react";
import Footer from "../Footer";
import Header from "../Header";
import Hero from "../Hero";
import Title from "../Title";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <Header excludeSubMenu />
          <Hero type="cv">
            <Title withMargin>Whoops!</Title>
            <Title withMargin>Something went wrong</Title>
          </Hero>
          <Footer excludeTopLink />
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
