import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import Container from "../Container";
import SocialLink from "../SocialLink";
import styles from "./index.module.css";

const Footer = ({ excludeTopLink }) => {
  return (
    <footer className={styles.footer}>
      {!excludeTopLink && (
        <div>
          <a
            data-testid="footer-scrollup"
            href="#header"
            className={styles.arrow}
          >
            <FontAwesomeIcon icon={faAngleUp} />
          </a>
        </div>
      )}

      <Container>
        <div className={styles.footerContent}>
          <div>
            <p>© Copyright {new Date().getFullYear()} Nick Bennett.</p>
          </div>
          <div>
            <SocialLink type="facebook" />
            <SocialLink type="twitter" />
            <SocialLink />
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
