import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import DOMPurify from "dompurify";
import { blogConfig } from "../../Component/Header/data";
import usePost from "../../Queries/post";
import Author from "../../Component/Author";
import Container from "../../Component/Container";
import ContainerInfo from "../../Component/ContainerInfo";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Hero from "../../Component/Hero";
import Loader from "../../Component/Loader";
import Section from "../../Component/Section";
import Title from "../../Component/Title";
import Categories from "./Categories";
import styles from "./index.module.css";

const PostPage = () => {
  const { postId } = useParams();
  const { isLoading, error, data: post } = usePost(postId);
  const [cleanContent, setCleanContent] = useState("");
  const [cleanExcerpt, setCleanExcerpt] = useState("");
  const [cleanTitle, setCleanTitle] = useState("");

  useEffect(() => {
    console.log("data", post);
    if (post) {
      setCleanExcerpt(
        DOMPurify.sanitize(post.excerpt, {
          USE_PROFILES: { html: true },
        })
      );
      setCleanContent(
        DOMPurify.sanitize(post.content, {
          USE_PROFILES: { html: true },
        })
      );
      setCleanTitle(
        DOMPurify.sanitize(post.title, {
          USE_PROFILES: { html: true },
        })
      );
    }
  }, [post, setCleanContent, setCleanTitle]);

  return (
    <>
      <Header config={blogConfig} />
      <Hero short>
        <ContainerInfo classExtra={styles.infoContainerHigher}>
          {isLoading && <Loader white />}
          {!isLoading && !error && (
            <>
              <Title
                id="blog-title"
                dangerouslySetInnerHTML={{ __html: cleanTitle }}
              />
            </>
          )}
        </ContainerInfo>
      </Hero>
      <Section grey classExtra={styles.sectionBlogMetaContainer}>
        <Container>
          {!isLoading && !error && (
            <>
              <div className={styles.blogMetaContainer}>
                <Author author={post.author} />
                <div className={styles.blogMeta}>
                  <strong>{post.author.name}</strong> on{" "}
                  {format(new Date(post.date), "do MMM yyyy")}
                  <Categories categories={post.categories} />
                </div>
              </div>
            </>
          )}
        </Container>
      </Section>
      <Section classExtra={styles.blogSection}>
        <Container>
          <div className={styles.blogContainer}>
            {isLoading && (
              <div className={styles.loaderContainer}>
                <Loader />
              </div>
            )}
            {!isLoading && !error && (
              <>
                {post.featuredImage && (
                  <div className={styles.blogContainerImageContainer}>
                    <img
                      className={styles.blogContainerImage}
                      src={
                        post.featuredImage.availableSizes["full"]["source_url"]
                      }
                      alt="Post thumbnail"
                    />
                  </div>
                )}
                <div
                  className={styles.excerpt}
                  dangerouslySetInnerHTML={{ __html: cleanExcerpt }}
                />
                <p
                  className={styles.blogContainerContent}
                  dangerouslySetInnerHTML={{ __html: cleanContent }}
                />
              </>
            )}
          </div>
        </Container>
      </Section>
      <Footer />
    </>
  );
};

export default PostPage;
