const career = [
  {
    month: "Jan",
    year: "2022",
    title: "Promoted",
    details: "Became Orchestration team lead",
  },
  {
    month: "Jul",
    year: "2020",
    title: "Became AWS Certified Cloud Practitioner",
    details: "Initial certification on AWS",
  },
  {
    month: "Jul",
    year: "2019",
    title: "Joined Cutover as a senior developer",
    details: "Start of a new journey.",
  },
  {
    month: "Jun",
    year: "2018",
    title: "Started contracting at GoHenry",
    details: "Joined to help clear backlog of backend PHP API tickets.",
  },
  {
    month: "Nov",
    year: "2017",
    title: "ITIL Foundation qualification",
    details:
      "Completed the 3 day ITIL Foundation course and passed the final exam.",
  },
  {
    month: "Jun",
    year: "2016",
    title: "Started contracting at AJ Bell",
    details:
      "Joined to implement several complex projects. These included client on-boarding, GDPR implementation, appropriateness questionnaire and machine learning API",
  },
  {
    month: "Apr",
    year: "2014",
    title: "Joined Interactive Investor",
    details:
      "Acted as scrum master for the beta ground up re-write project. Developed the trading modal and client on-boarding for the beta project. Helped deliver white label trading platforms.",
  },
  {
    month: "Jun",
    year: "2013",
    title: "Trained in Agile project management",
    details:
      "Qualified from the course that covered Agile principles and Scrum.",
  },
  {
    month: "Jun",
    year: "2010",
    title: "IAQ qualification gained",
    details: "Completed all 3 exams required to gain the qualification.",
  },
  {
    month: "Oct",
    year: "2008",
    title: "Joined Raymond James Investment Services",
    details:
      "Trained and acted as scrum master on the payroll project. Developed main Portal for client holding access. Implemented CLI scripts for imports and automation of releases.",
  },
  {
    month: "Apr",
    year: "2006",
    title: "Joined Digital Look",
    details:
      "Developed sections on the main Digital Look site including the personal finance section and i-look.",
  },
  {
    month: "Jul",
    year: "2004",
    title: "Joined Nomad",
    details:
      "Helped deliver a multitude of digital solutions for local businesses.",
  },
  {
    month: "Aug",
    year: "2001",
    title: "Media Technology Degree",
    details: "Gained a 2-1 BSc degree from De Montfort university",
  },
  {
    month: "Jun",
    year: "1998",
    title: "A-levels gained",
    details: "3 A levels in Maths and Science",
  },
  {
    month: "Jun",
    year: "1996",
    title: "GCSEs gained",
    details: "10 including maths, English and Science",
  },
];

export default career;
