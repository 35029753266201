import React from "react";
import { useHistory } from "react-router-dom";
import MenuItem from "../MenuItem";
import styles from "./index.module.css";

const Menu = ({ config, excludeSubMenu = false, displayMenu = false }) => {
  const history = useHistory();

  const handleNavClick = (menuItem) => {
    if (menuItem.id) {
      document.getElementById(menuItem.id).scrollIntoView();
    } else if (menuItem.link) {
      history.push(`${menuItem.link}`);
    }
  };

  return (
    <nav className={styles.nav}>
      <ul className={styles.menu}>
        {!excludeSubMenu && (
          <>
            {config &&
              config.map((menuItem, key) => (
                <MenuItem
                  key={key}
                  id={key}
                  icon={menuItem.icon}
                  label={menuItem.label}
                  onClickHandler={() => {
                    handleNavClick(menuItem);
                  }}
                  classExtra={displayMenu ? "" : "hidden"}
                />
              ))}
          </>
        )}
      </ul>
    </nav>
  );
};

export default Menu;
