import React from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import FilterGrid from "../../Component/FilterGrid";
import {
  getFeaturedPortfolioFilters,
  getFeaturedPortfolios,
} from "../../Data/portfolio";
import ButtonRow from "../../Component/ButtonRow";
import Button from "../../Component/Button";
import Container from "../../Component/Container";
import Section from "../../Component/Section";
import SectionTitle from "../../Component/SectionTitle";

const HomePortfolio = () => {
  return (
    <Section id="portfolio">
      <Container>
        <SectionTitle
          title="My Portfolio"
          icon={faBriefcase}
          subTitle="Please see some previous projects I've worked on"
        />
        <FilterGrid
          filters={getFeaturedPortfolioFilters()}
          cards={getFeaturedPortfolios()}
        />
        <ButtonRow>
          <Link to="/work">
            <Button icon={faBriefcase} label="Full Portfolio" color="green" />
          </Link>
        </ButtonRow>
      </Container>
    </Section>
  );
};

export default HomePortfolio;
