import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./index.module.css";

const Block = ({
  icon,
  heading,
  info,
  content,
  size = "standard",
  noHover = false,
}) => {
  const classExtra = size === "large" ? styles.boxWrapperLarge : null;
  const hoverExtra = !noHover ? styles.boxWithHover : null;

  return (
    <div data-testid="block" className={`${styles.boxWrapper} ${classExtra}`}>
      <div data-testid="block-box" className={`${styles.box} ${hoverExtra}`}>
        <FontAwesomeIcon
          data-testid="block-icon"
          icon={icon}
          className={styles.boxIcon}
        />
        <h3 className={styles.boxHeader}>{heading}</h3>
        {info && <p>{info}</p>}
        {content && content}
      </div>
    </div>
  );
};

export default Block;
