import React from "react";
import about from "../../Assets/Images/about/about.png";
import Container from "../../Component/Container";
import ProgressBars from "../../Component/ProgressBars";
import {
  PromoBlockContainer,
  PromoBlockContent,
  PromoBlockPanel,
  PromoBlockFlex,
  PromoBlockImg,
  PromoBlockText,
} from "../../Component/PromoBlock";
import Section from "../../Component/Section";
import SubTitle from "../../Component/SubTitle";
import VerticalSubNav from "../../Component/VerticalSubNav";
import aboutTabs from "../../Data/aboutTabs";
import skills from "../../Data/skills";
import styles from "./HomeAbout.module.css";

const HomeAbout = () => {
  const [selectedAboutTab, setSelectedAboutTab] = React.useState(
    Object.keys(aboutTabs)[0]
  );

  return (
    <Section>
      <Container>
        <PromoBlockContainer classExtra={styles.promoBlockPadding}>
          <VerticalSubNav
            tabs={aboutTabs}
            onClick={(key) => {
              setSelectedAboutTab(key);
            }}
          />
          <PromoBlockContent>
            <PromoBlockPanel>
              {Object.keys(aboutTabs).map((key) => {
                const currentTab = aboutTabs[key];

                return (
                  <div
                    id={`tab_${key}`}
                    key={key}
                    role="tabpanel"
                    className={`${styles.aboutPanel} ${
                      selectedAboutTab !== key ? "hidden" : ""
                    }`}
                    aria-labelledby={`tab_${key}_title`}
                  >
                    <SubTitle title={currentTab.tooltip} />
                    {(() => {
                      switch (key) {
                        case "skill":
                          return (
                            <div className={styles.skillContainer}>
                              <ProgressBars mapper={skills} />
                            </div>
                          );

                        case "why":
                          return (
                            <>
                              <PromoBlockText>
                                I am a very conscientious engineer and am always
                                looking out for my client's best interests. I've
                                always been comfortable raising concerns openly
                                and honestly. Considering the reputational
                                impact of releasing an untested piece of
                                functionality for example.
                              </PromoBlockText>
                              <PromoBlockText>
                                My experience means I'm comfortable leading
                                teams and can communicate well with other
                                business units. I've carried out scrum master
                                activities including running standups, reviews,
                                and retrospectives. Also as a team lead and
                                manager, HR duties including 1 to 1's,
                                appraisals and disciplinary procedures
                              </PromoBlockText>
                            </>
                          );
                        case "vision":
                          return (
                            <>
                              <PromoBlockText>
                                Things have moved progressively towards a more
                                server-less approach. This micro computing
                                approach opens lots of doors to potential
                                innovators that were previously held back by
                                cost.
                              </PromoBlockText>
                              <PromoBlockText>
                                I'm not seeing a lot of momentum around web 3.0.
                                Although I have the admit that Blockchain
                                technology does fascinate me. ChatGPT is
                                currently the big concern for engineers, but for
                                me a good engineer is also someone that can
                                adapt.
                              </PromoBlockText>
                            </>
                          );
                        default:
                          return (
                            <PromoBlockFlex>
                              <PromoBlockImg img={about} imgAlt="About me" />
                              <div>
                                <PromoBlockText>
                                  I am a senior software engineer with over 10
                                  years of experience in the finance sector. As
                                  a keen advocate of the Agile manifesto, I
                                  habitually use test-driven development and
                                  adhere to the SOLID principles.
                                </PromoBlockText>
                                <PromoBlockText>
                                  I have worked on a broad range of projects
                                  using a large variety of technologies. I have
                                  experience leading small teams and have acted
                                  as a scrum master on several projects. I prize
                                  myself on being an excellent problem solver,
                                  whether the problem is process, machine or
                                  human.
                                </PromoBlockText>
                              </div>
                            </PromoBlockFlex>
                          );
                      }
                    })()}
                  </div>
                );
              })}
            </PromoBlockPanel>
          </PromoBlockContent>
        </PromoBlockContainer>
      </Container>
    </Section>
  );
};

export default HomeAbout;
