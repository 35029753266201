import React from "react";
import styles from "./index.module.css";

const Title = ({ children, withMargin = false, ...nativeProps }) => (
  <h1
    data-testid="title"
    className={`${styles.title} ${withMargin ? styles.sunken : ""}`}
    {...nativeProps}
  >
    {children}
  </h1>
);

export default Title;
