import React, { useEffect } from "react";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Main from "../../Component/Main";
import HomeAbout from "./HomeAbout";
import HomeCompanies from "./HomeCompanies";
import HomeEducation from "./HomeEducation";
import HomeHero from "./HomeHero";
import HomePortfolio from "./HomePortfolio";
import HomeServices from "./HomeServices";
import HomeTestimonials from "./HomeTestimonials";

const HomePage = ({ title }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <>
      <Header />
      <Main id="home-hero">
        <HomeHero />
        <HomeAbout />
        <HomeServices />
        <HomePortfolio />
        <HomeEducation />
        <HomeTestimonials />
        <HomeCompanies />
      </Main>
      <Footer />
    </>
  );
};

export default HomePage;
